/**
 * Created by nick on 1/9/2020.
 */
import React from "react"
import { Link } from "gatsby"

import MainLayout from "../components/mainlayout"
import SEO from "../components/seo"

const Privacy = () => (
    <MainLayout>

        <section class="hero recipes-section spad pt-0">
            <div class="hero-body">
            <div class="container">
                <SEO title="privacy" />
                <h1>Privacy Policy</h1><br/>
<div class="ne elHeadline hsSize1 lh5 elMargin0 elBGStyle0 hsTextShadow0" data-bold="inherit"
     data-gramm="false" contenteditable="false">At {getSiteURL()}, accessible from https://{getSiteURL()}, one
    of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information
    that is collected and recorded by {getSiteURL()} and how we use it.<br/><br/>If you have additional questions or
        require more information about our Privacy Policy, do not hesitate to contact us through email at
        info@{getSiteURL()}<br/><br/><b>Log Files</b><br/>{getSiteURL()} follows a standard procedure of using log
            files. These files log visitors when they visit websites. All hosting companies do this and a part of
            hosting services' analytics. The information collected by log files include internet protocol (IP)
            addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and
            possibly the number of clicks. These are not linked to any information that is personally identifiable. The
            purpose of the information is for analyzing trends, administering the site, tracking users' movement on the
            website, and gathering demographic information.<br/><br/><b>Cookies and Web Beacons</b><br/>Like any other
                website, {getSiteURL()} uses 'cookies'. These cookies are used to store information including
                visitors' preferences, and the pages on the website that the visitor accessed or visited. The
                information is used to optimize the users' experience by customizing our web page content based on
                visitors' browser type and/or other information.<br/><br/><b>Privacy Policies</b><br/>You may consult this
                    list to find the Privacy Policy for each of the advertising partners of {getSiteURL()}. Our
                    Privacy Policy was created with the help of the Privacy Policy Generator.<br/><br/>Third-party ad
                        servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used
                        in their respective advertisements and links that appear on {getSiteURL()}, which are sent
                        directly to users' browser. They automatically receive your IP address when this occurs. These
                        technologies are used to measure the effectiveness of their advertising campaigns and/or to
                        personalize the advertising content that you see on websites that you visit.<br/><br/>Note that
                            {getSiteURL()} has no access to or control over these cookies that are used by
                            third-party advertisers.<br/><br/><b>Third Party Privacy Policies</b><br/>{getSiteURL()}'s
                                Privacy Policy does not apply to other advertisers or websites. Thus, we are advising
                                you to consult the respective Privacy Policies of these third-party ad servers for more
                                detailed information. It may include their practices and instructions about how to
                                opt-out of certain options. You may find a complete list of these Privacy Policies and
                                their links here: Privacy Policy Links.<br/><br/>You can choose to disable cookies through
                                    your individual browser options. To know more detailed information about cookie
                                    management with specific web browsers, it can be found at the browsers' respective
                                    websites. What Are Cookies?<br/><br/><b>Children's Information</b><br/>Another part of
                                        our priority is adding protection for children while using the internet. We
                                        encourage parents and guardians to observe, participate in, and/or monitor and
                                        guide their online activity.<br/><br/>{getSiteURL()} does not knowingly
                                            collect any Personal Identifiable Information from children under the age of
                                            13. If you think that your child provided this kind of information on our
                                            website, we strongly encourage you to contact us immediately and we will do
                                            our best efforts to promptly remove such information from our
                                            records.<br/><br/><b>Online Privacy Policy Only</b><br/>This Privacy Policy
                                                applies only to our online activities and is valid for visitors to our
                                                website with regards to the information that they shared and/or collect
                                                in {getSiteURL()}. This policy is not applicable to any information
                                                collected offline or via channels other than this website.<br/><br/><b>Consent</b><br/>By
                                                    using our website, you hereby consent to our Privacy Policy and
                                                    agree to its Terms of Use.<br/><br/>
</div>

            </div>
            </div>
        </section>
    </MainLayout>
)

export default Privacy

function getSiteURL() {
    const site = process.env.GATSBY_SITE;
    return site;
}


